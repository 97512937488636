import { MenuItem } from 'primevue/menuitem'
import {
  EducationRouteNames,
  CMSRouteNames,
  TalentRouteNames,
  JobBankRouteNames,
  CommunityRouteNames
} from '@/router/route-names'
import { ref } from 'vue'
export default ref<MenuItem[]>([
  {
    label: 'Educación',
    items: [
      {
        label: 'Comisiones',
        to: {
          name: EducationRouteNames.COURSES
        }
      },
      {
        label: 'Contenido',
        to: {
          name: EducationRouteNames.CONTENT_COURSES
        }
      },
      {
        label: 'Seguimiento Staff',
        to: {
          name: EducationRouteNames.STAFF_FOLLOWUP_TEACHERS_ONBOARDING
        }
      },
      {
        label: 'Estudiantes',
        to: {
          name: EducationRouteNames.STUDENTS_DETAILS
        }
      }
    ]
  },
  {
    label: 'CoderPlus',
    items: [
      {
        label: 'Videos',
        to: {
          name: 'Videos'
        }
      }
    ]
  },
  {
    label: 'Reclutamiento',
    items: [
      {
        label: 'Contratos',
        disabled: true,
        to: {
          name: 'ContractTypes'
        }
      },
      {
        label: 'Estructura',
        to: {
          name: 'Structure'
        }
      },
      {
        label: 'Squads',
        to: {
          name: 'StaffSquads'
        }
      },
      {
        label: 'Staff',
        to: {
          name: 'StaffList'
        }
      }
    ]
  },
  {
    label: 'Talento',
    disabled: true,
    items: []
  },
  {
    label: 'Comunidad',
    items: [
      {
        label: 'Referidos',
        to: {
          name: CommunityRouteNames.REFERALS
        }
      }
    ]
  },
  {
    label: 'CMS',
    items: [
      {
        label: 'Traducciones',
        to: {
          name: CMSRouteNames.TRANSLATIONS
        }
      },
      {
        label: 'Banners',
        to: {
          name: CMSRouteNames.BANNERS
        }
      },
      {
        label: 'Events',
        to: {
          name: CMSRouteNames.EVENTS
        }
      },
      {
        label: 'Empresas Co-creadoras',
        to: {
          name: CMSRouteNames.PRODUCT_BRANDS
        }
      },
      {
        label: 'Categorias de Producto',
        to: {
          name: CMSRouteNames.PRODUCT_CATEGORIES
        }
      },
      {
        label: 'Alianzas',
        to: {
          name: CMSRouteNames.ALLIANCES
        }
      },
      {
        label: 'Carga de SVG',
        to: {
          name: CMSRouteNames.SVG_UPLOAD
        }
      }
    ]
  },
  {
    label: 'Finanzas',
    disabled: true,
    items: []
  },
  {
    label: 'CoderTalent',
    items: [
      {
        label: 'Reportes',
        to: {
          name: TalentRouteNames.REPORTS
        }
      }
    ]
  },
  {
    label: 'Bolsa de empleos',
    items: [
      {
        label: 'Empresas',
        to: {
          name: JobBankRouteNames.COMPANIES
        }
      },
      {
        label: 'Ofertas Laborales',
        to: {
          name: JobBankRouteNames.JOB_OFFERS
        }
      },
      {
        label: 'Ofertas Scrapeadas',
        to: {
          name: JobBankRouteNames.JOB_SCRAPER
        }
      }
    ]
  }
])
