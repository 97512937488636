
import { defineComponent } from 'vue'
import HorizontalMenu from '@/layouts/components/HorizontalMenu.vue'
import LayoutMain from '@/layouts/wrappers/LayoutMain.vue'
import LateralMenu from '@/layouts/components/LateralMenu.vue'

export default defineComponent({
  name: 'FullLayout',
  components: {
    HorizontalMenu,
    LayoutMain,
    LateralMenu
  }
})
