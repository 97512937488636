import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-575d7173")
const _hoisted_1 = {
  key: 0,
  class: "side-menu-container"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("aside", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]))
    : _createCommentVNode("", true)
}