
import { defineComponent, ref } from 'vue'
import SideMenuContainer from '../wrappers/SideMenuContainer.vue'
import PanelMenu from 'primevue/panelmenu'
import lateralMenuItems from '@/layouts/lateral-menu-items'
import { useRouter } from 'vue-router'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { CommonRouteNames } from '@/router/route-names'
export default defineComponent({
  components: {
    SideMenuContainer,
    PanelMenu
  },
  setup () {
    const router = useRouter()
    const session = useUserSessionStore()
    const items = ref([
      {
        label: 'Home',
        to: {
          name: CommonRouteNames.HOME_PAGE
        }
      },
      {
        label: 'Cerrar sesión',
        command: () => {
          session.logout().then(() => router.push({ name: CommonRouteNames.LOGIN_PAGE }))
        }
      }
    ])
    return {
      lateralMenuItems,
      items
    }
  }
})
