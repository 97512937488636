import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-13987f90")
const _hoisted_1 = { class: "side-menu-header" }
const _hoisted_2 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelMenu = _resolveComponent("PanelMenu")!
  const _component_SideMenuContainer = _resolveComponent("SideMenuContainer")!

  return (_openBlock(), _createBlock(_component_SideMenuContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        _createElementVNode("img", {
          src: require('@/assets/icons/logo-coder.svg'),
          alt: ""
        }, null, 8, _hoisted_2)
      ]),
      _createVNode(_component_PanelMenu, {
        exact: false,
        class: "navigation-menu",
        model: _ctx.lateralMenuItems
      }, null, 8, ["model"]),
      _createVNode(_component_PanelMenu, { model: _ctx.items }, null, 8, ["model"])
    ]),
    _: 1
  }))
}